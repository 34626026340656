import PropTypes from "prop-types"
import React from "react"
import "./header.scss"


const Header = () => (
  <div className="sticky-inner header-outter">
    <img className="header-logo" src={require('../images/itp_banner_min.png')}/>
    <div className="contact-info">
      <div className="social-info">
        <a href="https://twitter.com/ITP_Productions" rel="noopener noreferrer" target="_blank">
          <img className="social-icon" src={require('../images/twitter.png')}/>
        </a>
        <a href="https://www.instagram.com/islandtimepictures/?hl=en" rel="noopener noreferrer" target="_blank">
          <img className="social-icon ml-sm" src={require('../images/instagram.png')}/>
        </a>
      </div>
      <div className="header-email">
        <p>IslandTimePictures@gmail.com</p>
      </div>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
