import React from "react"
import "./about.scss"

const About = () => (
  <div className="about-main">
    <h1>About Us</h1>
    <div className="about-info shaddow">
        <img className="about-picture" src={require('../images/itp_flower.png')}/>
        <p className="about-text">If you are wondering what production should look like heading down the road, don’t look any further than what Ian McClellan, MFA, USC School of Cinematic Arts Production Program, and Natalie Combs, MFA, USC Peter Stark Producing Program, are starting with their new venture Island Time Pictures (ITP). The two met producing both of their first thesis film, Pearl, and bonded over work outside of school as crew on major union shows and movies. Now with their own shingle, the pair unite their yin and yang of below and above the line producing capabilities.</p>
        <p className="about-text">Combs and McClellan look to mix their deep grasp of fundamentals with a sharp instinct for what’s funky and fresh. They believe their creative sensibility and an unmatchable work ethic make them ideal creative partners. So far the numbers agree. Their produced digital projects have racked up a combined viewership of almost 100 million views, clocking in at 91,407,001 from YouTube drops Vader: Shards of the Past and international music videos like Vegas Jones: Malibu and Dark Polo Gang: British.</p>
        <p className="about-text">“The idea behind our company name is simple,” says Combs, who honed her flash and dazzle techniques on network TV’s largest shows like The Voice. “We realized great productions start with the right mindset, with a calm and collected focus on telling the story in the best way possible,” she explains. Thus they alighted on the name Island Time Pictures. </p>
        <p className="about-text">McClellan, who brings a grounded approach to the high-pressure demands of production, is quick to point out that their techniques are still sophisticated. “For Vader, we coordinated several dozen 2-D and 3-D CGI artists around the globe via Discord servers and multiple post producers.”</p>
        <p className="about-text">“We work hard to combine the vision of our creative collaborators, the health, safety and wellbeing of our crews, and the practical constraints of financing into the most captivating projects possible,” says Combs, an avid Dodgers fan.</p>
        <p className="about-text">“The best feeling for us at ITP is that everyone is happy and entertained, from the creatives and financiers to the viewing audiences at home,” finishes McClellan, an aficionado of coffee spots across Los Angeles.</p>
        <p className="about-text">While their methods are unconventional, they are focused on efficiency. They are actively looking to welcome new creators, explore emerging platforms and innovate with classic and new stories well told. We anticipate great things coming from Island Time Pictures in the near future.</p>
    </div>
</div>
)

export default About

