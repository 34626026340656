/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Header from "./header"
import Biography from "./biography"
import About from "./about"
import Footer from "./footer"
import "./layout.scss"

const Layout = () => {
  return (
    <>
      <div className="top">
        <div className="w-100 contact-email">
          <p>IslandTimePictures@gmail.com</p>
        </div>
        <div className="picture-outter mlr-auto">
          <img className="logo-picture mlr-auto fade-in-slow" src={require('../images/itp_banner_min.png')}/>
        </div>
      </div>
      <div className="main">
        <div className="sticky-wrapper sticky">
          <Header></Header>
        </div>
        <div className="place-holder"></div>
        <div className="content">
          <div className="website-info">
            <div className="left-column mlr-auto">
              <h1 className="mlr-auto">Trending Now</h1>
              <div className="shaddow twitter-box">
                <a className="twitter-timeline" href="https://twitter.com/ITP_Productions?ref_src=twsrc%5Etfw">Tweets by ITP_Productions</a> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
              </div>
              <Biography></Biography>
            </div>
            <div className="right-column mlr-auto">
              <About></About>
              <div className="real-box">
                <h1 className="mlr-auto">Now Playing</h1>
                <div className="real-box">
                  <video className="real" id="myvideo" controls autoPlay muted>
                    <source src="https://itp.beholddevelopment.com/videos/itp_real.mp4" type="video/mp4"/>
                  </video>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  )
}

export default Layout
