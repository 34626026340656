import React from "react"
import "./biography.scss"

const About = () => (
  <div className="biography-main">
    <h1>Who we are</h1>
    <div className="biography-info shaddow">
        <img className="biography-picture" src={require('../images/Ian_Nacho.jpg')}/>
        <p className="biography-text">
            Natalie Combs grew up on a farm in Indiana. She started in show business doing staging services
            in Chicago then moved to Los Angeles, California. There she worked behind the scenes on some
            of the top rated television shows in the United States (The Voice, The Masked Singer, Dancing
            with the Stars). She earned her MFA in Creative Producing from The Peter Stark Producing
            Program at the University of Southern California’s School of Cinematic Arts in 2016. Her
            independently produced projects with top international music artists like Vegas Jones and Dark
            Polo Gang and directors like Gabrielle Muccino have tens of millions of views on YouTube and
            other platforms. She enjoys bringing her major network experience to the digital world to make
            seriously fun content.
        </p>
        <p className="biography-text">
            Ian McClellan started as a company electrician on the 2012 Sundance Grand Jury Prize-winning
            and Academy Award-nominated film Beasts of the Southern Wild. He went on to associate
            produce for Academy Award-winning filmmakers Rob Epstein and Jeffrey Friedman on their
            documentary The Battle of AmfAR which premiered at Sundance 2013. He earned his MFA in
            producing for film and television from the University of Southern California’s School of
            Cinematic Arts in December 2016. A student thesis he co-produced, Lalo’s House, won a
            Student Academy Award in 2018. He now runs his production company Island Time Pictures,
            focused on enabling creators to realize their unique artist visions.
        </p>
    </div>
</div>
)

export default About
