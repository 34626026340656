import React from "react"
import "./footer.scss"


const Footer = () => (
  <div className="footer-outter">
      <div className="trademark-stuff">
          <p>© 2020 IslandTimePictures</p>
          <img className="trademark-picture" src={require('../images/gatsby-icon.png')}/>
      </div>
      <div className="designer">
          <p>Designed and Built by BeholdDevelopment</p>
      </div>
  </div>
)



export default Footer
